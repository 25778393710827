import React, { useState, useEffect } from 'react';
import './ClientTimer.css';

const ClientTimer = ({ name, initialMinutes, initialSeconds, onRemove }) => {
    const [time, setTime] = useState((initialMinutes * 60 + initialSeconds) * 1000);
    const [isRunning, setIsRunning] = useState(false);
    const alarmAudio = new Audio('/alarm.mp3');  // Chemin vers le fichier audio d'alarme

    useEffect(() => {
        let interval = null;
        if (isRunning && time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1000);
            }, 1000);
        } else if (time <= 0) {
            clearInterval(interval);
            setIsRunning(false);
            playAlarm();
        }
        return () => clearInterval(interval);
    }, [isRunning, time]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const startStopTimer = () => {
        setIsRunning(!isRunning);
    };

    const resetTimer = () => {
        setTime((initialMinutes * 60 + initialSeconds) * 1000);
        setIsRunning(false);
    };

    const playAlarm = () => {
        alarmAudio.play().catch(error => console.log("Erreur de lecture audio :", error));
    };


    return (
        <div className="client-timer">
            <h3>{name}</h3>
            <div className="time-display">{formatTime(time)}</div>
            <div className="button-container">
                <button onClick={startStopTimer} className="button">
                    {isRunning ? 'Pause' : 'Démarrer'}
                </button>
                <button onClick={resetTimer} className="button">Réinitialiser</button>
                <button onClick={() => onRemove(name)} className="button remove-button">Supprimer</button>
            </div>
        </div>
    );
};

export default ClientTimer;
