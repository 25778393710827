import React, { useState } from 'react';
import ClientTimer from './ClientTimer';
import './App.css';

function App() {
    const [clients, setClients] = useState([]);
    const [clientName, setClientName] = useState('');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const addClient = () => {
        if (clientName.trim() !== '') {
            setClients([
                ...clients,
                {
                    name: clientName,
                    minutes: parseInt(minutes),
                    seconds: parseInt(seconds)
                }
            ]);
            setClientName('');
            setMinutes(0);
            setSeconds(0);
        }
    };

    const removeClient = (name) => {
        setClients(clients.filter(client => client.name !== name));
    };

    return (
        <div className="app">
            <h1>Gestion des Chronomètres Clients</h1>
            <div className="add-client-form">
                <input
                    type="text"
                    placeholder="Nom du client"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Minutes"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                    min="0"
                />
                <input
                    type="number"
                    placeholder="Secondes"
                    value={seconds}
                    onChange={(e) => setSeconds(e.target.value)}
                    min="0"
                    max="59"
                />
                <button onClick={addClient}>Ajouter Client</button>
            </div>
            <div className="clients-container">
                {clients.map((client) => (
                    <ClientTimer
                        key={client.name}
                        name={client.name}
                        initialMinutes={client.minutes}
                        initialSeconds={client.seconds}
                        onRemove={removeClient}
                    />
                ))}
            </div>
        </div>
    );
}

export default App;
